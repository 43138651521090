import React from "react"
import Modal from "react-modal"

Modal.setAppElement("#___gatsby")

class ManagementCard extends React.Component {
  constructor() {
    super()
    this.state = {
      modalIsOpen: false,
      selected: 0,
    }
    this.openModal = this.openModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(index) {
    if (this.props.isTopManagement == true) {
      document.querySelector("html").style.overflow = "hidden"
      this.setState({
        modalIsOpen: true,
        selected: index,
      })
    }
  }

  afterOpenModal() {
    this.subtitle.style.color = "#f00"
  }

  closeModal() {
    document.querySelector("html").style.overflow = "unset"
    this.setState({ modalIsOpen: false })
  }

  render() {
    console.log(this.props)
    return (
      <>
        {this.props.managers.map((manager, index) => (
          <>
            <div className="director column is-one-quarter">
              <div
                className="card is-inline"
                key={index}
                onClick={() => this.openModal(index)}
              >
                <div className="card-image">
                  <figure className="image">
                    <img
                      src={manager.cover}
                      style={{ position: `sticky`, top: `8px` }}
                    />
                  </figure>
                  <div className="image-desc">
                    <h3 id="hover-name">{manager.name}</h3>
                    <h3>{manager.role}</h3>
                  </div>
                </div>
              </div>
            </div>

            {this.props.isTopManagement && (
              <Modal
                className="managementmodal"
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.closeModal}
                style={{
                  overlay: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.25)",
                  },
                  content: {
                    position: "absolute",
                    // border: '1px solid #ccc',
                    background: "rgba(98,98,98,1.0)",
                    // overflow: 'auto',
                    WebkitOverflowScrolling: "touch",
                    borderRadius: "4px",
                    outline: "none",
                    padding: "20px",
                  },
                }}
              >
                <a
                  href="#"
                  className="close delete is-large"
                  onClick={this.closeModal}
                />
                <div className="container">
                  <div className="columns is-mobile is-multiline">
                    <div className="column is-3-desktop is-4-mobile is-4-tablet">
                      <img
                        src={this.props.managers[this.state.selected].cover}
                        style={{ position: `sticky`, top: `25px` }}
                      />
                    </div>
                    <div className="director name column is-4-desktop is-8-mobile is-3-tablet">
                      <div className="columns is-multiline">
                        <div className="column is-full">
                          <h3
                            className="title is-primary is-paddingless is-marginless"
                            style={{ color: `white` }}
                          >
                            {this.props.managers[this.state.selected].name}
                          </h3>
                        </div>
                        <div className="column is-full">
                          <h5 className="subtitle has-text-weight-bold">
                            {this.props.managers[this.state.selected].role}
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div
                      className="director content column is-4-desktop "
                      id="management_content"
                      style={{
                        overflowY: `scroll`,
                        height: `365px`,
                        marginTop: `20px`,
                      }}
                    >
                      <p>Age: {this.props.managers[this.state.selected].age}</p>
                      <p>
                        Gender:{" "}
                        {this.props.managers[this.state.selected].gender}
                      </p>
                      <p>
                        Nationality:{" "}
                        {this.props.managers[this.state.selected].nationality}
                      </p>
                      <p>
                        Date Appointed:{" "}
                        {
                          this.props.managers[this.state.selected]
                            .date_appointed_to_board
                        }
                      </p>

                      <p>Qualification</p>
                      {this.props.managers[
                        this.state.selected
                      ].qualifications.map((qualification, index) => (
                        <ul>
                          <li>{qualification.title}</li>
                        </ul>
                      ))}
                      <br />
                      <p>Working Experience And Occupation</p>
                      {this.props.managers[
                        this.state.selected
                      ].working_experience_and_occupations.map(
                        (experience, index) => (
                          <ul>
                            <li>{experience.title}</li>
                          </ul>
                        )
                      )}
                      <br />
                      <p>Date Appointed To Board:</p>
                      <ul>
                        <li>
                          {
                            this.props.managers[this.state.selected]
                              .date_appointed_to_board
                          }
                        </li>
                      </ul>
                      <br />

                      <p>Details Of Any Board Committee Involved:</p>
                      <ul>
                        <li>
                          {
                            this.props.managers[this.state.selected]
                              .board_committee
                          }
                        </li>
                      </ul>
                      <br />
                      <p>
                        Other Directorship In Public Companies And Listed
                        Issuers:
                      </p>
                      <ul>
                        <li>
                          {
                            this.props.managers[this.state.selected]
                              .other_directorship
                          }
                        </li>
                      </ul>
                      <br />
                      <p>
                        Any Family Relationship With Any Director And/Or Major
                        Shareholder Of The Listed Issuer:
                      </p>
                      <ul>
                        <li>
                          {
                            this.props.managers[this.state.selected]
                              .family_relationship
                          }
                        </li>
                      </ul>
                      <br />
                      <p>Any Conflict Of Interest With The Listed Issuer:</p>
                      <ul>
                        <li>
                          {
                            this.props.managers[this.state.selected]
                              .conflict_of_interest
                          }
                        </li>
                      </ul>
                      <br />
                      <p>
                        List Of Convictions For Offences Within The Past 5 Years
                        And Particulars Of Any Public Sanction Or Penalty
                        Imposed By The Relevant Regulatory Bodies During The
                        Financial Year, If Any:
                      </p>
                      <ul>
                        <li>
                          {this.props.managers[this.state.selected].convictions}
                        </li>
                      </ul>
                      <br />
                      {/* <p>
                        Number Of Board Meetings Attended In The Financial Year:
                        </p>
                        <p>
                        {
                          this.props.managers[this.state.selected]
                          .board_meetings_attended
                        }
                      </p> */}
                    </div>
                  </div>
                </div>
              </Modal>
            )}
          </>
        ))}
      </>
    )
  }
}

export default ManagementCard
