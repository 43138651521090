import React from "react"
// import PropTypes from 'prop-types'
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import PageWrapper from "../layouts/PageWrapper"
import ManagementCard from "../components/ManagementCard"
import HomeLogo from "../components/HomeLogo"

export const ManagementPageTemplate = ({
  title,
  meta_description,
  meta_title,
  top_management_team,
  management_team,
}) => (
  <PageWrapper navbarClassName="landing-page">
    <Helmet>
      <title>Management Team - E.A. Technique</title>
    </Helmet>
    <section className="hero is-small" id="management_page-hero">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column">
              <HomeLogo />
              <h1 className="title" id="title-line">
                Management Team
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="hero" id="management_page-section">
      <div className="hero-body">
        <div className="container">
          <Tabs>
            <TabList>
              <Tab>Top Management Team</Tab>
              <Tab>Management Team</Tab>
            </TabList>

            <TabPanel>
              <div className="columns is-multiline">
                <ManagementCard
                  managers={top_management_team}
                  isTopManagement
                />
              </div>
            </TabPanel>

            <TabPanel>
              <div className="columns is-multiline">
                <ManagementCard
                  managers={management_team}
                  isTopManagement={false}
                />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </section>
  </PageWrapper>
)

export const ManagementPage = ({ data }) => {
  console.log(data)

  const { frontmatter } = data.allMarkdownRemark.edges[0].node

  return (
    <ManagementPageTemplate
      title={frontmatter.title}
      meta_title={frontmatter.meta_title}
      meta_description={frontmatter.meta_description}
      top_management_team={frontmatter.top_management_team}
      management_team={frontmatter.management_team}
    />
  )
}

export default ManagementPage

export const managementPageQuery = graphql`
  query ManagementPage {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "management-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            meta_title
            meta_description
            heading
            description
            top_management_team {
              name
              role
              cover
              age
              gender
              nationality
              qualifications {
                title
              }
              working_experience_and_occupations {
                title
              }
              date_appointed_to_board
              board_committee
              other_directorship
              family_relationship
              conflict_of_interest
              convictions
              board_meetings_attended
            }
            management_team {
              name
              role
              cover
            }
          }
        }
      }
    }
  }
`
